import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes, FaGithub, FaLinkedin, FaHome } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';
import resumePDF from '../assets/resume/resume.pdf';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    <div
      className={`z-50 fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0A192F] text-white text-opacity-90 ${
        nav ? 'bg-[#0A192F]' : ''
      }`}
    >
      <div>
        <h1
          className="hover:cursor-pointer hover:text-cyan-600 p-2 rounded bg-gradient-to-r from-cyan-900 to-blue-950 text-bold text-2xl"
          data-original-text="ES"
        >
          <Link to="home" smooth={true} duration={500}>
            ES
          </Link>
        </h1>
      </div>
      <ul className="hidden md:flex">
        <Link to="home" smooth={true} duration={500}>
          <li className="mx-2 text-white border-2 px-4 py-2 my-2 rounded-lg flex items-center hover:bg-cyan-600 hover:text-amber-400 border-cyan-800 hover-scale">
            <div className="bg-[#0A192F] px-2 py-1 rounded-full">
              <FaHome className="text-white" />
            </div>
          </li>
        </Link>
        <Link to="about" smooth={true} duration={500}>
          <li className="mx-2 text-white border-2 px-4 py-2 my-2 rounded-lg flex items-center hover:bg-cyan-600  border-cyan-800 hover-scale">
            About
          </li>
        </Link>
        <Link to="projects" smooth={true} duration={500}>
          <li className="mx-2 text-white border-2 px-4 py-2 my-2 rounded-lg flex items-center hover:bg-cyan-600 0 border-cyan-800 hover-scale">
            Projects
          </li>
        </Link>
        <Link to="skills" smooth={true} duration={500}>
          <li className="mx-2 text-white border-2 px-4 py-2 my-2 rounded-lg flex items-center hover:bg-cyan-600 border-cyan-800 hover-scale">
            Skills
          </li>
        </Link>
        <Link to="contact" smooth={true} duration={500}>
          <li className="mx-2 text-white border-2 px-4 py-2 my-2 rounded-lg flex items-center hover:bg-cyan-600  border-cyan-800 hover-scale">
            Contact
          </li>
        </Link>
      </ul>

      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#0A192F] flex flex-col justify-center items-center'
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="projects"
            smooth={true}
            duration={500}
          >
            Projects
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <div className="flex">
            <a
              className="mx-3"
              target="blank_"
              href="https://www.linkedin.com/in/eraysahin06/"
            >
              <FaLinkedin />
            </a>
            <a
              className="mx-3"
              target="blank_"
              href="https://github.com/eraysahin06"
            >
              <FaGithub />
            </a>
          </div>
        </li>
      </ul>
      <div className="hidden lg:flex flex-col fixed top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/eraysahin06/"
              target="blank"
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://github.com/eraysahin06"
              target="blank"
            >
              GitHub <FaGithub size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#16a34a]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="mailto:eraysahindev@gmail.com"
              target="blank"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#065f46]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href={resumePDF}
              download="resume.pdf"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
