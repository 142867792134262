import React from 'react';
import { Link } from 'react-scroll';

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-[#0a192f] flex justify-center items-center p-4 pt-12"
    >
      <form
        method="POST"
        action="https://getform.io/f/acbfe6a2-8f84-4725-b78e-134f41091cc3"
        className="flex flex-col max-w-[600px] w-full"
      >
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-cyan-600 text-gray-300">
            Contact
          </p>
          <p className="text-gray-300 py-4">
            Submit the form below with your message, or send me an e-mail -
            <span className="text-amber-400">eraysahindev@gmail.com</span>
          </p>
        </div>
        <input
          className="bg-[#ccd6f6] p-2 rounded-lg"
          type="text"
          placeholder="Name"
          name="name"
          required
        />
        <input
          className="my-4 p-2 bg-[#ccd6f6] rounded-lg"
          type="email"
          placeholder="E-mail"
          name="email"
          required
        />
        <textarea
          className="bg-[#ccd6f6] p2 rounded-lg"
          name="message"
          rows="10"
        ></textarea>
        <button className="text-white border-2 hover:bg-cyan-600 hover:border-cyan-600 px-4 py-3 my-8 mx-auto flex items-center rounded-lg">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
