import React from 'react';
import { FaUnity, FaGithub } from 'react-icons/fa'; // Import Unity and GitHub icons from react-icons
import { SiCsharp } from 'react-icons/si'; // Import C# icon
import { GiLoveSong } from 'react-icons/gi';

const Featured = () => {
  return (
    <div id="featured" className="bg-[#0A192F] pt-[200px]">
      <div className="max-w-[1000px] mx-auto px-8 text-center">
        <h2 className="text-4xl sm:text-5xl font-bold text-[#ccd6f6] mb-8">
          Featured Project
        </h2>

        <div className="max-w-2xl mx-auto">
          <h3 className="text-3xl font-bold text-[#ccd6f6] mb-4">
            Laser Blink
          </h3>
          <p className="text-[#8892b0] mb-4">
            My first mobile game, "Laser Blink," is now available on the Google
            Play Store. It's built with the Unity Game Engine using the C#
            programming language.
          </p>

          <div className="flex items-center justify-center mb-4">
            <FaUnity className="text-5xl text-white mr-4" />
            <SiCsharp className="text-5xl text-white mr-4" />
            <GiLoveSong className="text-5xl text-white" />
          </div>

          <p className="text-[#8892b0] mb-8">
            The game's soundtrack, created by me, is produced using FL Studio.
          </p>

          <div className="flex justify-center align-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.Ermoon.LaserBlink"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex align-center justify-center bg-cyan-600 text-white px-8 py-3 rounded-full hover:bg-cyan-700 transition duration-300 mr-4"
            >
              View on Google Play
            </a>

            <a
              href="https://github.com/eraysahin06/laser-blink" // Replace with your actual GitHub repository link
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex align-center justify-center bg-gray-700 text-white px-8 py-3 rounded-full hover:bg-gray-800 transition duration-300"
            >
              <FaGithub className="text-2xl mr-2" />
              <span>Source Code</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
