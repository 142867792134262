import React from 'react';
import { Link } from 'react-scroll';
import { HiArrowNarrowRight as Arrow } from 'react-icons/hi';
import { FaLinkedin, FaGithub } from 'react-icons/fa'; // Import LinkedIn and GitHub icons
import BetterLivesLogo from '../assets/better.png';
import EmpoweredFuturesLogo from '../assets/Empowered.png';

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-cyan-600">
              About Me
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>Helping Create Tomorrow's Innovations...</p>
            <div className="flex justify-evenly mt-8">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://makingbetterlives.ca/"
              >
                <img
                  className="w-40 transform scale-100 hover:scale-20 transition-transform border border-cyan-600 rounded-2xl"
                  src={BetterLivesLogo}
                  alt="Making Better Lives Logo"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.empoweredfutures.ca/"
              >
                <img
                  className="w-40 transform scale-100 hover:scale-20 transition-transform border border-cyan-600 rounded-2xl p-7"
                  src={EmpoweredFuturesLogo}
                  alt="Making Better Lives Logo"
                />
              </a>
            </div>
          </div>

          <div>
            <p className="text-[#8892b0] py-4 max-w-[700px] font-bold">
              I am currently collaborating with two non-profit organizations to
              develop{' '}
              <span className="text-amber-400">real-world applications</span>.
              One of them is{' '}
              <a
                href="https://www.empoweredfutures.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-cyan-600"
              >
                Empowered Futures
              </a>{' '}
              as a frontend web developer, and the second one is{' '}
              <a
                href="https://makingbetterlives.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-cyan-600"
              >
                Making Better Lives
              </a>{' '}
              as a Java Android Developer. <br />
              <br /> Additionally, I am an active contributor to{' '}
              <span className="text-amber-400">
                open-source projects
              </span> on{' '}
              <a
                href="https://github.com/eraysahin06"
                target="_blank"
                rel="noopener noreferrer"
                className="text-cyan-600"
              >
                GitHub
              </a>
              , and I am building my own projects with the aim of making a
              meaningful impact in the real world.
            </p>
          </div>
          <div className="flex mt-4">
            <Link to="projects" smooth={true} duration={500}>
              <button className="group text-white border-2 px-6 py-3 ml-2 my-2 rounded-lg flex items-center hover:bg-cyan-600 hover:border-cyan-600">
                Projects
                <span className="group-hover:rotate-90 duration-300">
                  <Arrow className="ml-3" />
                </span>
              </button>
            </Link>
            {/* LinkedIn and GitHub links */}
            <div className="flex items-center ml-2">
              {/* GitHub link */}
              <a
                href="https://github.com/eraysahin06"
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl text-cyan-600 hover:text-amber-400 ml-4 border-2 px-6 py-2 rounded-lg"
              >
                <FaGithub />
              </a>
              {/* LinkedIn link */}
              <a
                href="https://www.linkedin.com/in/eraysahin06/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl text-cyan-600 hover:text-amber-400 ml-4 border-2 px-6 py-2 rounded-lg"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
