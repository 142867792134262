import React from 'react';
import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import NodeJS from '../assets/node.png';
import GitHub from '../assets/github.png';
import Azure from '../assets/Azure.png';
import Python from '../assets/Python.png';
import Django from '../assets/Django.png';
import Java from '../assets/Java.webp';
import Selenium from '../assets/Selenium.png';
import TestNG from '../assets/TESTNG.webp';
import CSharp from '../assets/csharp.png';
import Unity from '../assets/unity.png';
import Cucumber from '../assets/cucumber.png';
import ExpressIcon from '../assets/express.png';

const Skills = () => {
  return (
    <div name="skills" className="bg-[#0a192f] text-gray-300 w-full mt-[200px]">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-cyan-600">
            Core Technologies and Tools
          </p>
          <p className="py-4">
            These are the Technologies and Tools I{' '}
            <span className="text-amber-400">Frequently</span> Use
          </p>
        </div>

        {/* Web Development Section */}
        <div className="mb-8">
          <p className="text-2xl font-semibold">Web Development</p>
          <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-4">
            {/* HTML */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={HTML} alt="HTML icon" />
              <p className="my-4">HTML</p>
            </div>
            {/* CSS */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={CSS} alt="CSS icon" />
              <p className="my-4">CSS</p>
            </div>
            {/* JavaScript */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto"
                src={JavaScript}
                alt="JavaScript icon"
              />
              <p className="my-4">JavaScript</p>
            </div>
            {/* React */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={ReactImg} alt="React icon" />
              <p className="my-4">React</p>
            </div>
          </div>
        </div>

        {/* Backend Section */}
        <div className="mb-8">
          <p className="text-2xl font-semibold">Backend</p>
          <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-4">
            {/* Node.js */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={NodeJS} alt="Node.js icon" />
              <p className="my-4">Node.js</p>
            </div>
            {/* Express */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto"
                src={ExpressIcon}
                alt="Express icon"
              />
              <p className="my-4">Express</p>
            </div>
            {/* Django */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={Django} alt="Django icon" />
              <p className="my-4">Django</p>
            </div>
            {/* Python */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={Python} alt="Python icon" />
              <p className="my-4">Python</p>
            </div>
          </div>
        </div>

        {/* Game Development Section */}
        <div className="mb-8">
          <p className="text-2xl font-semibold">Game Development</p>
          <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-4">
            {/* C# */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={CSharp} alt="C# icon" />
              <p className="my-4">C#</p>
            </div>
            {/* Unity */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={Unity} alt="Unity icon" />
              <p className="my-4">Unity</p>
            </div>
          </div>
        </div>
        {/* Testing Section */}
        <div className="mb-8">
          <p className="text-2xl font-semibold">Testing</p>
          <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-4">
            {/* Selenium */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto"
                src={Selenium}
                alt="Selenium icon"
              />
              <p className="my-4">Selenium - WebDriver</p>
            </div>
            {/* TestNG */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-21 h-20 mx-auto"
                src={TestNG}
                alt="TestNG icon"
              />
              <p className="my-4">TestNG</p>
            </div>
            {/* Cucumber */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto"
                src={Cucumber}
                alt="Cucumber icon"
              />
              <p className="my-4">Cucumber</p>
            </div>
            {/* Java */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={Java} alt="Java icon" />
              <p className="my-4">Java</p>
            </div>
          </div>
        </div>

        {/* Other Tools */}
        <div className="mb-8">
          <p className="text-2xl font-semibold">Other Tools</p>
          <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-4">
            {/* GitHub */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={GitHub} alt="GitHub icon" />
              <p className="my-4">GitHub</p>
            </div>
            {/* Azure */}
            <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto" src={Azure} alt="Azure icon" />
              <p className="my-4">Microsoft Azure</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
