import React, { useState, useEffect } from 'react';
import { HiArrowNarrowRight as Arrow } from 'react-icons/hi';
import { Link } from 'react-scroll';
import { BsDownload } from 'react-icons/bs';
import ErayLinkedin from '../assets/eraylinked.png';
import resumePDF from '../assets/resume/resume.pdf';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setIsLoaded(true);
    }, 300); // Adjust the delay time as needed
  }, []);

  return (
    <div name="home" className="w-full h-screen bg-[#0A192F]">
      {/* CONTAINER */}
      <div
        className={`max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full ${
          isLoaded
            ? 'opacity-1 transform translate-y-0 transition-all duration-1000 ease-in-out'
            : 'opacity-0 transform -translate-y-10 transition-all duration-1000 ease-in-out'
        }`}
      >
        <p className="text-cyan-500">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Eray Sahin
        </h1>

        <h2 className="text-4xl font-bold text-[#8892b0]">
          <span className="text-amber-400">Full Stack Web Developer</span> /
          <span className="text-amber-400"> Tester</span>
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          I am a passionate software developer actively creating and
          contributing to projects that drive
          <span className="text-amber-600"> innovation</span> and{' '}
          <span className="text-amber-600">collaboration</span>.
        </p>
        <div className="flex">
          <Link to="about" smooth={true} duration={500}>
            <button className="group text-white border-2 px-6 py-3 my-2 rounded-lg flex items-center hover:bg-cyan-600 hover:border-cyan-600">
              About Me
              <span className="group-hover:rotate-90 duration-300">
                <Arrow className="ml-3" />
              </span>
            </button>
          </Link>
          <a href={resumePDF} download="resume.pdf">
            <button className="ml-3 group text-white border-2 px-6 py-3 my-2 rounded-lg flex items-center hover:bg-cyan-600 hover:border-cyan-600">
              Resume
              <span>
                <BsDownload className="ml-3" />
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
