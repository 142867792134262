import React from 'react';
import ErayDesktop from '../assets/projects/ErayDesktop.jpg';
import fabriqua from '../assets/projects/fabriqua.png';
import bloom from '../assets/projects/bloom.png';
import LogicBlog from '../assets/projects/LogicBlog.jpg';
import TCriptify from '../assets/projects/T-Criptify.png';

const Projects = () => {
  const projects = [
    {
      image: ErayDesktop,
      title: 'E-RAY Diecast Store',
      description:
        'Your premier destination for high-quality diecast cars, powered by Next.js and MongoDB.',
      tools:
        'Tools used: NextJS, MongoDB, Stripe API, AWS Buckets, Google Authentication, Styled Components',
      href: 'https://e-ray.vercel.app/',
      code: 'https://github.com/eraysahin06/e-ray',
    },

    {
      image: LogicBlog,
      title: 'Logic Blog',
      description:
        'A blog website with categories and accounts. Made with ExpressJS and Handlebars for views. Used Cloudinary for photos.',
      tools:
        'Tools used: Express, Handlebars.js, PostgreSQL, MongoDB, Bootstrap',
      href: 'https://logic-blog.vercel.app/',
      code: 'https://github.com/eraysahin06/logic-blog',
    },
    {
      image: TCriptify,
      title: 'T-Criptify',
      description:
        'A web application that exports transcription from an uploaded video. Made with NextJS, AWS and Tailwind CSS.',
      tools:
        'Tools used: Next JS, React JS, AWS S3 Buckets, AWS Transcribe, Tailwind',
      href: 'https://t-criptify.vercel.app/',
      code: 'https://github.com/eraysahin06/t-criptify',
    },
    {
      image: fabriqua,
      title: 'Fabriqua',
      description:
        'A freelance work for Fabriqua, includes showroom information and a product list.',
      tools: 'Tools used: React JS, Tailwind, Vite',
      href: 'https://fabriqua-react-twcss.vercel.app/',
      code: 'https://github.com/eraysahin06/fabriqua-react-twcss',
    },
    {
      image: bloom,
      title: 'Bloom Tile',
      description:
        'A freelance work for Bloom Tile, comes with an admin panel to upload, edit and delete products.',
      tools:
        'Tools used: React JS, Tailwind, Node.js, Express, MongoDB, Mongoose',
      href: 'https://bloom-tile-react-mac-edits.vercel.app/',
      code: 'https://github.com/eraysahin06/bloom-tile-react-mac-edits',
    },
  ];

  return (
    <div
      name="projects"
      className="bg-[#0a192f] w-full md:h-screen mt-20 text-gray-300"
    >
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-300 border-cyan-600">
            Recent Projects
          </p>
          <p className="py-6">
            These are some of my most{' '}
            <span className="text-amber-400">recent</span> projects, showcasing
            my <span className="text-amber-400">skills</span> and{' '}
            <span className="text-amber-400">creativity</span> in web
            development.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
          {projects.map((project, index) => (
            <div key={index} className="bg-sky-950 rounded-lg shadow-lg p-4">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-auto rounded-md mb-4 transition-transform transform hover:scale-110"
              />

              <span className="text-2xl font-bold text-white tracking-wider">
                {project.title}
              </span>
              <p className="text-gray-400 py-2">{project.description}</p>
              <p className="text-amber-400 py-2">{project.tools}</p>
              <div className="text-center flex items-center justify-center mt-2">
                <a rel="noreferrer" target="_blank" href={project.href}>
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#0a192f] text-white font-bold text-lg transform transition-colors hover:bg-[#4a90e2] hover:scale-102">
                    Demo
                  </button>
                </a>
                <a rel="noreferrer" target="_blank" href={project.code}>
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-[#0a192f] text-white font-bold text-lg transform transition-colors hover:bg-amber-600 hover:scale-102">
                    Source Code
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
