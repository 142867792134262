import React from 'react';

const Footer = () => {
  return (
    <div className="bg-[#0a192f] text-gray-400 h-20 flex justify-center items-center">
      <p className="text-center">2023 Eray Sahin</p>
    </div>
  );
};

export default Footer;
